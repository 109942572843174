<template>
  <div class="product-box-wide__segment-center">
    <a
      class="product-box-wide__header product-box__segment-header"
      :href="productBoxData.url"
    >
      <div class="product-box-wide__header-text product-box__header-text">
        <span class="product-box-wide__name product-box__name">
          {{ productBoxData.name }}
        </span>
        <span
          class="product-box-wide__short-description product-box__short-description"
          v-if="productBoxData.shortDescription"
          v-html="productBoxData.shortDescription"
        />
      </div>
    </a>
    <div
      v-if="productBoxData.ratings > minimumRating"
      class="product-box-wide__rating-container"
    >
      <ratings
        class="product-box-wide__ratings"
        :rating="productBoxData.ratings"
        type="disabled"
      />
    </div>
    <div
      class="product-box-wide__tags-wrapper product-box__tags-wrapper"
      :class="{
        'product-box-wide__tags-wrapper--no-set': !productBoxData.numberOfBottlesInSet || productBoxData.numberOfBottlesInSet < 2,
        'product-box-wide__tags-wrapper--has-tags': tags && tags.length > 0,
        'product-box-wide__tags-wrapper--has-flag': productBoxData.flags.vegan,
      }"
      role="presentation"
    >
      <span
        class="product-box-wide__bottle-amount"
        v-if="productBoxData.numberOfBottlesInSet && productBoxData.numberOfBottlesInSet >= 2"
      >
        <span>
          {{ $t('volumeUnit.numberOfBottlesInSet', [productBoxData.numberOfBottlesInSet]) }}
        </span>
      </span>
      <span
        v-for="(tag) in tags"
        :key="tag.name"
        class="tags__list"
      >
        <span class="tags__tag | tag">
          {{ tag.name }}
        </span>
      </span>
      <span
        v-if="productBoxData.flags.nonAlcoholic"
        class="flag flag--nonAlcoholic"
        :class="{
          'flag--with-dot': productBoxData.flags.vegan
        }"
      >
        <span>{{ $t('flags.nonAlcoholic') }}</span>
      </span>
      <span
        v-if="productBoxData.flags.vegan"
        class="flag flag--vegan"
      >
        <span>{{ $t('flags.vegan') }}</span>
      </span>
    </div>
    <div
      class="product-box-wide__long-description"
      v-html="descriptionExcerpt"
    />
  </div>
</template>
<script lang="ts">
  import {Vue, Options, Prop} from 'vue-property-decorator';
  import Ratings from '../../../bps/components/ratings/ratings.vue';
  import Tags from '../../../bps/components/tags/tags.vue';
  import type ProductBoxData from '../../../bps/types/ProductBoxData';
  import Flags from '../../../bps/components/flags/flags.vue';
  import {ProductBoxWideSettings} from './productBoxWideSettings';

  @Options({
    name: 'product-box-wide-segment-center',
    components: {Flags, Ratings, Tags}
  })
  export default class ProductBoxWideSegmentCenter extends Vue {
    @Prop()
    descriptionExcerpt: String;
    @Prop()
    minimumRating: String;
    @Prop()
    basketUrl: String;
    @Prop()
    productBoxData: ProductBoxData;
    @Prop()
    settings: ProductBoxWideSettings;

    get tags() {
      return this.productBoxData.tags.filter(tag => tag.type !== 'BULK_PRICE');
    }
  }
</script>
