import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "product-box-wide__segment-center" }
const _hoisted_2 = ["href"]
const _hoisted_3 = { class: "product-box-wide__header-text product-box__header-text" }
const _hoisted_4 = { class: "product-box-wide__name product-box__name" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = {
  key: 0,
  class: "product-box-wide__rating-container"
}
const _hoisted_7 = {
  key: 0,
  class: "product-box-wide__bottle-amount"
}
const _hoisted_8 = { class: "tags__tag | tag" }
const _hoisted_9 = {
  key: 2,
  class: "flag flag--vegan"
}
const _hoisted_10 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ratings = _resolveComponent("ratings")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("a", {
      class: "product-box-wide__header product-box__segment-header",
      href: _ctx.productBoxData.url
    }, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.productBoxData.name), 1 /* TEXT */),
        _createTextVNode(),
        (_ctx.productBoxData.shortDescription)
          ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              class: "product-box-wide__short-description product-box__short-description",
              innerHTML: _ctx.productBoxData.shortDescription
            }, null, 8 /* PROPS */, _hoisted_5))
          : _createCommentVNode("v-if", true)
      ])
    ], 8 /* PROPS */, _hoisted_2),
    _createTextVNode(),
    (_ctx.productBoxData.ratings > _ctx.minimumRating)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createVNode(_component_ratings, {
            class: "product-box-wide__ratings",
            rating: _ctx.productBoxData.ratings,
            type: "disabled"
          }, null, 8 /* PROPS */, ["rating"])
        ]))
      : _createCommentVNode("v-if", true),
    _createTextVNode(),
    _createElementVNode("div", {
      class: _normalizeClass(["product-box-wide__tags-wrapper product-box__tags-wrapper", {
        'product-box-wide__tags-wrapper--no-set': !_ctx.productBoxData.numberOfBottlesInSet || _ctx.productBoxData.numberOfBottlesInSet < 2,
        'product-box-wide__tags-wrapper--has-tags': _ctx.tags && _ctx.tags.length > 0,
        'product-box-wide__tags-wrapper--has-flag': _ctx.productBoxData.flags.vegan,
      }]),
      role: "presentation"
    }, [
      (_ctx.productBoxData.numberOfBottlesInSet && _ctx.productBoxData.numberOfBottlesInSet >= 2)
        ? (_openBlock(), _createElementBlock("span", _hoisted_7, [
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('volumeUnit.numberOfBottlesInSet', [_ctx.productBoxData.numberOfBottlesInSet])), 1 /* TEXT */)
          ]))
        : _createCommentVNode("v-if", true),
      _createTextVNode(),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tags, (tag) => {
        return (_openBlock(), _createElementBlock("span", {
          key: tag.name,
          class: "tags__list"
        }, [
          _createElementVNode("span", _hoisted_8, _toDisplayString(tag.name), 1 /* TEXT */)
        ]))
      }), 128 /* KEYED_FRAGMENT */)),
      _createTextVNode(),
      (_ctx.productBoxData.flags.nonAlcoholic)
        ? (_openBlock(), _createElementBlock("span", {
            key: 1,
            class: _normalizeClass(["flag flag--nonAlcoholic", {
          'flag--with-dot': _ctx.productBoxData.flags.vegan
        }])
          }, [
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('flags.nonAlcoholic')), 1 /* TEXT */)
          ], 2 /* CLASS */))
        : _createCommentVNode("v-if", true),
      _createTextVNode(),
      (_ctx.productBoxData.flags.vegan)
        ? (_openBlock(), _createElementBlock("span", _hoisted_9, [
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('flags.vegan')), 1 /* TEXT */)
          ]))
        : _createCommentVNode("v-if", true)
    ], 2 /* CLASS */),
    _createTextVNode(),
    _createElementVNode("div", {
      class: "product-box-wide__long-description",
      innerHTML: _ctx.descriptionExcerpt
    }, null, 8 /* PROPS */, _hoisted_10)
  ]))
}