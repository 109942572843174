import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["aria-label"]
const _hoisted_2 = {
  key: 0,
  class: "price-info__old"
}
const _hoisted_3 = { class: "price-info__old-text" }
const _hoisted_4 = { class: "price-info__old-value" }
const _hoisted_5 = { class: "price-info__old-value-euro" }
const _hoisted_6 = { class: "price-info__old-value-cent" }
const _hoisted_7 = { class: "price-info__currency" }
const _hoisted_8 = { class: "price-info__current" }
const _hoisted_9 = { class: "price-info__value" }
const _hoisted_10 = { class: "price-info__value-euro" }
const _hoisted_11 = { class: "price-info__value-cent" }
const _hoisted_12 = { class: "price-info__currency" }
const _hoisted_13 = {
  key: 0,
  class: "price-info__asterisk"
}
const _hoisted_14 = {
  key: 1,
  class: "price-info__savedPercentage"
}
const _hoisted_15 = ["href", "innerHTML"]
const _hoisted_16 = { class: "price-info__unit" }
const _hoisted_17 = { class: "price-info__unit-text" }
const _hoisted_18 = {
  key: 0,
  class: "price-info__unit-price"
}
const _hoisted_19 = { class: "price-info__tax-wrapper" }
const _hoisted_20 = ["innerHTML"]
const _hoisted_21 = {
  key: 1,
  class: "price-info__tax--article-number"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["price-info", {
      'price-info--reduction': _ctx.value.old,
      [`price-info--currency-${_ctx.value.currency}`]: true
    }]),
    "aria-label": _ctx.$t('checkout.basket.position.header.price') + ' ' + _ctx.value.current
  }, [
    (_ctx.value.old)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('price.instead_of')), 1 /* TEXT */),
          _createTextVNode(),
          _createElementVNode("span", _hoisted_4, [
            _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.splitOldValue[0]) + _toDisplayString(_ctx.priceDot), 1 /* TEXT */),
            _createTextVNode(),
            _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.splitOldValue[1]), 1 /* TEXT */),
            _createTextVNode(),
            _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.value.currency), 1 /* TEXT */)
          ])
        ]))
      : _createCommentVNode("v-if", true),
    _createTextVNode(),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("span", _hoisted_9, [
        _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.splitCurrentValue[0]) + _toDisplayString(_ctx.priceDot), 1 /* TEXT */),
        _createTextVNode(),
        _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.splitCurrentValue[1]), 1 /* TEXT */)
      ]),
      _createTextVNode(),
      _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.value.currency), 1 /* TEXT */),
      _createTextVNode(),
      (_ctx.showStar)
        ? (_openBlock(), _createElementBlock("sup", _hoisted_13, "\n        *\n      "))
        : _createCommentVNode("v-if", true),
      _createTextVNode(),
      (_ctx.showSavedPercentage && _ctx.value.savedPercentage)
        ? (_openBlock(), _createElementBlock("span", _hoisted_14, "\n        -" + _toDisplayString(_ctx.value.savedPercentage) + "%\n      ", 1 /* TEXT */))
        : _createCommentVNode("v-if", true)
    ]),
    _createTextVNode(),
    _createElementVNode("div", {
      class: _normalizeClass(["price-info__info", {
        'price-info__info--inline': _ctx.foodSpecificationLink
      }])
    }, [
      (_ctx.foodSpecificationLink)
        ? (_openBlock(), _createElementBlock("a", {
            key: 0,
            class: "price-info__food-specification-link",
            href: _ctx.foodSpecificationLink,
            innerHTML: _ctx.$t('detail.product.characteristics')
          }, null, 8 /* PROPS */, _hoisted_15))
        : _createCommentVNode("v-if", true),
      _createTextVNode(),
      _createElementVNode("div", _hoisted_16, [
        _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.getUnitText()), 1 /* TEXT */),
        _createTextVNode(),
        (_ctx.value.unitPrice)
          ? (_openBlock(), _createElementBlock("span", _hoisted_18, [
              _createTextVNode("\n          (" + _toDisplayString(_ctx.value.unitPrice), 1 /* TEXT */),
              (_ctx.value.unit)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createTextVNode("/" + _toDisplayString(_ctx.value.unit), 1 /* TEXT */)
                  ], 64 /* STABLE_FRAGMENT */))
                : _createCommentVNode("v-if", true),
              _createTextVNode(")\n        ")
            ]))
          : _createCommentVNode("v-if", true)
      ])
    ], 2 /* CLASS */),
    _createTextVNode(),
    _createElementVNode("div", _hoisted_19, [
      (_ctx.showTax)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "price-info__tax",
            innerHTML: _ctx.$t(_ctx.showShippingIncluded ? _ctx.messageKey.deliveryInclShip : _ctx.messageKey.deliveryInclVat, [_ctx.shippingInfoUrl])
          }, null, 8 /* PROPS */, _hoisted_20))
        : _createCommentVNode("v-if", true),
      _createTextVNode(),
      (_ctx.articleNumber.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_21, _toDisplayString(_ctx.$t('checkout.basket.position.productNr')) + " " + _toDisplayString(_ctx.articleNumber), 1 /* TEXT */))
        : _createCommentVNode("v-if", true)
    ])
  ], 10 /* CLASS, PROPS */, _hoisted_1))
}