<template>
  <div class="product-box__segment-main">
    <ratings
      class="product-box__ratings"
      v-if="productBoxData.ratings > minimumRating && isBoxTypeVertical"
      :rating="productBoxData.ratings"
      :ratings-counter="productBoxData.ratingsCounter"
      :type="settings.rating.type"
      :lazy-load="true"
    />
    <lazy-load
      class="product-box__tags-wrapper"
      v-if="(productBoxData.flags || productBoxData.tags) && isBoxTypeVertical"
      role="presentation"
    >
      <tags
        class="product-box__tags"
        :class="{
          'product-box__tags--more': productBoxData.tags.length > 2,
          'product-box__tags--has-award': productBoxData.flags.awardImageUrls && productBoxData.flags.awardImageUrls.length
        }"
        v-if="isBoxTypeVertical"
        :tags="tags"
        @quantityChange="$emit('quantityChange', $event)"
      />
      <flags
        :flags="productBoxData.flags"
        :limit="isBoxTypeVertical ? 4 : 3"
        :award-limit="isBoxTypeVertical ? 2 : 1"
        :white-list="settings.flags.whitelistTag"
      />
    </lazy-load>
    <div
      v-if="productBoxData.zeroPriceItem || productBoxData.recurringOrderFollowUpPacket"
      class="product-box__price-wrapper product-box__price-wrapper--no-price"
    >
      <div class="product-box__buttons">
        <a
          class="product-box__status product-box__status--unavailable"
          :href="productBoxData.url"
        >
          {{ $t('availability.no-price') }}
        </a>
      </div>
    </div>
    <div
      class="product-box__conversion"
      v-else
    >
      <div class="product-box__info-wrapper">
        <div
          v-if="bulkPrice"
          class="product-box__bulk-price"
        >
          <div
            class="product-box__bulk-price-info"
            v-html="bulkPrice"
            @click="$emit('quantityChange', bulkPriceQuantity)"
          />
        </div>
        <availability-info
          v-if="showAvailability"
          class="product-box__availability"
          :availability="productBoxData.availability"
        />
        <winemaker-price
          v-if="productBoxData.winemakerPrice"
        />
      </div>
      <div
        class="product-box__price-wrapper"
        v-if="productBoxData.price || productBoxData.soldoutRelation"
      >
        <price-info
          v-if="!hidePrice"
          class="product-box__price"
          :value="productBoxData.price"
          :alcohol="productBoxData.alcohol"
          :show-star="true"
        />
      </div>
      <div class="product-box__buttons-wrapper">
        <product-qty-and-cart-btn
          :product-data="productBoxData"
          :product-link="productLink"
          :state="state"
          :add-successor-active="addSuccessorActive"
          @quantityChange="$emit('quantityChange', $event)"
          @addToBasket="$emit('addToBasket', $event)"
          @onRequestClicked="$emit('onRequestClicked', $event)"
        />
        <a
          class="product-box__status product-box__status--basket"
          v-if="productBoxData.inBasket"
          :href="basketUrl"
        >
          {{ $t('ads.ads_top_info.article_in_basket') }}
        </a>
        <a
          class="product-box__status product-box__status--basket product-box__status--successor-basket"
          v-if="productBoxData.successorInBasket && !productBoxData.inBasket && addSuccessorActive"
          :href="basketUrl"
        >
          {{ $t('ads.ads_top_info.successor_in_basket') }}
        </a>
        <a
          v-if="productBoxData.showFoodSpecificationLink && !hideCounterButton"
          :href="foodSpecificationLink"
          class="product-box__link"
          v-html="$t('detail.product.characteristics')"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import {Options, Prop, Vue} from 'vue-property-decorator';
  import AvailabilityInfo from '../../../bps/components/availabilityInfo/availabilityInfo.vue'
  import Ratings from '../../../bps/components/ratings/ratings.vue'
  import Lightbox from '../../../bps/components/lightbox/lightbox.vue'
  import Tags from '../../../bps/components/tags/tags.vue'
  import Flags from '../../../bps/components/flags/flags.vue'
  import type ProductBoxData from '../../../bps/types/ProductBoxData';
  import {ProductBoxSettings} from './productBoxSettings';
  import ProductQtyAndCartBtn from '../../../bps/components/productQtyAndCartBtn/productQtyAndCartBtn.vue';
  import InfoPopup from '../../../bps/components/infoPopup/infoPopup.vue';
  import WinemakerPrice from '../../../bps/components/winemakerPrice/winemakerPrice.vue';
  import Tag from '../../../bps/types/Tag';
  import BulkPrice from '../../../bps/types/BulkPrice';
  import LazyLoad from '@/bps/components/lazyLoad/lazyLoad.vue';

  @Options({
    name: 'ProductBoxSegmentMain',
    components: {
      LazyLoad,
      WinemakerPrice,
      ProductQtyAndCartBtn,
      AvailabilityInfo,
      Ratings,
      Flags,
      Lightbox,
      Tags,
      InfoPopup
    },
  })
  export default class ProductBoxSegmentMain extends Vue {
    @Prop()
    productBoxData: ProductBoxData;
    @Prop()
    settings: ProductBoxSettings;
    @Prop()
    basketUrl: string;
    @Prop()
    foodSpecificationLink: string;
    @Prop()
    subscriptionLink: string;
    @Prop()
    hideCounterButton: boolean;
    @Prop()
    isBoxTypeVertical: boolean;
    @Prop()
    minimumRating: number;
    @Prop()
    showAvailability: boolean;
    @Prop({default: false})
    hidePrice: boolean;
    @Prop({default: false})
    addSuccessorActive: boolean;
    @Prop()
    state: string;
    @Prop()
    productLink: string;

    get tags() {
      return this.productBoxData.tags.filter(tag => tag.type !== 'BULK_PRICE');
    }

    get bulkPriceQuantity() {
      const bulkTag: Array<Tag> = this.productBoxData.tags.filter(tag => tag.type === 'BULK_PRICE');
      if (bulkTag?.length > 0) {
        const info = bulkTag[0].info as BulkPrice;
        return info.quantity;
      }
      return null;
    }

    get bulkPrice() {
      const bulkTag: Array<Tag> = this.productBoxData.tags.filter(tag => tag.type === 'BULK_PRICE');
      if (bulkTag?.length > 0) {
        const info = bulkTag[0].info as BulkPrice;
        return info.price ? this.$t('tags.bulkPrice', [info.quantity, info.price]) : this.$t('tags.bulkPrice.noPrice', [info.quantity]);
      }
      return null;
    }
  }
</script>
