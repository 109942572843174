<template>
  <component
    :is="tag"
    class="product-box__segment-image"
  >
    <div
      class="product-box__rating-container"
      v-if="isBoxTypeHorizontal"
    >
      <div
        v-if="productBoxData.numberOfBottlesInSet >= 2"
      >
        <span class="product-box__bottle-amount">
          {{ $t('volumeUnit.numberOfBottlesInSet', [productBoxData.numberOfBottlesInSet]) }}
        </span>
      </div>
      <div>
        <ratings
          class="product-box__ratings"
          v-if="productBoxData.ratings > minimumRating"
          :rating="productBoxData.ratings"
          :ratings-counter="productBoxData.ratingsCounter"
          :type="settings.rating.type"
        />
      </div>
    </div>

    <component
      :is="productBoxData.valid ? 'a' : 'div'"
      class="product-box__image-container"
      :href="productLink"
      :target="productLink && target ? target : null"
    >
      <div
        class="product-box__product-flags"
        v-if="productBoxData.flags"
        role="presentation"
      >
        <flags
          :class="{'product-box__product-flags--hidden': productBoxData.inBasket}"
          :flags="productBoxData.flags"
          :limit="isBoxTypeVertical ? 4 : 2"
          :award-limit="isBoxTypeVertical ? 2 : 1"
          :white-list="settings.flags.whitelist"
        />
      </div>
      <div class="product-box__image-wrapper">
        <image-element
          class="product-box__image"
          :src="imgSrc"
          :alt="productBoxData.images[0].alt"
          :webp="true"
        />
      </div>
    </component>
  </component>
</template>

<script lang="ts">
  import {Options, Inject, Prop, Vue} from 'vue-property-decorator';
  import Flags from '../../../bps/components/flags/flags.vue'
  import ImageElement from '../../../bps/components/imageElement/imageElement.vue'
  import LazyLoad from '../../../bps/components/lazyLoad/lazyLoad.vue'
  import Ratings from '../../../bps/components/ratings/ratings.vue'
  import type ProductBoxData from '../../../bps/types/ProductBoxData';
  import {ProductBoxSettings} from './productBoxSettings';
  import {ProductBoxImageType} from '../../../bps/types/ProductBoxImage';
  import {transformIViewTemplates} from '../../utilities/js/imageUrlTransformer/vinosImageUrlTransformer';

  @Options({
    name: 'product-box-segment-image',
    components: {Flags, ImageElement, LazyLoad, Ratings},
  })
  export default class ProductBoxSegmentImage extends Vue {
    @Inject({default: false})
    useProductBoxBigImages: boolean;

    @Prop()
    productBoxData: ProductBoxData;
    @Prop()
    settings: ProductBoxSettings;
    @Prop()
    images: string[];
    @Prop()
    basketUrl: string;
    @Prop()
    productLink: string;
    @Prop()
    target: string;
    @Prop()
    isBoxTypeHorizontal: boolean;
    @Prop()
    isBoxTypeVertical: boolean;
    @Prop()
    minimumRating: number;
    @Prop({default: false})
    addSuccessorActive: boolean;
    @Prop({default: false})
    lazyLoad: boolean;

    get tag(): string {
      return this.lazyLoad ? 'lazy-load' : 'div';
    }

    get imgSrc(): string[] {
      let src: string[] = [];
      if (this.isBoxTypeVertical) {
        src = this.productBoxData.images.length > 2
          ? [this.productBoxData.images[ProductBoxImageType.Transparent].src, this.productBoxData.images[ProductBoxImageType.TransparentRetina].src]
          : [this.productBoxData.images[ProductBoxImageType.Transparent].src];
      } else {
        src = this.productBoxData.images.length > 2
          ? [this.productBoxData.images[ProductBoxImageType.Default].src, this.productBoxData.images[ProductBoxImageType.DefaultRetina].src]
          : [this.productBoxData.images[ProductBoxImageType.Default].src];
      }

      if (this.useProductBoxBigImages) {
        const transformMap = {
          'plistvertical_transparent': 'lgcarouselvertvinos',
          'plistvertical_transparent_webp': 'lgcarouselvertvinos_webp',
          'plistvertical_transparent_retina': 'lgcarouselvertvinos_retina',
          'plistvertical_transparent_retina_webp': 'lgcarouselvertvinos_retina_webp',
        }

        src = src.map((url) => transformIViewTemplates(url, transformMap))
      }

      return src;
    }
  }
</script>
